import React from "react";
import Layout from "../components/Layout";
import RightColumn from "../components/RightColumn";

export default class SamplesReportPage extends React.Component {
  render() {
    return (
      <Layout sidebarImageUrl="/images/photo06.jpg" sidebarImageAlt="" metaTitle="Samples &amp; Reports">
        <div id="mainContent">
          <p>
            <strong>New customer?</strong>&nbsp;&nbsp;
            <a href="/contact-us">Contact us</a> to set up and account and
            discuss how we can meet the needs of your organization. You can also
            call (479) 290-5062.
          </p>
          <p>
            Select the appropriate submission form below, and enter all required
            information.&nbsp; Customized submission forms will be stored in the
            customer&rsquo;s library.
          </p>
          <p>
            <a href="/documents/wba-submission-form.xlsx">
              Analytical Submission Form
            </a>
            <br />
            <a href="/documents/wba-nutritional-sub-form2.docx">
              Nutritional Labeling Submission Form
            </a>
          </p>
          <p>
            Reports will be made available via email and/or a secure library per
            customer volume and preference.&nbsp; Results reporting and
            retention will be agreed to when a request is made for a new account
            and can be updated upon request.&nbsp; To retrieve results from your
            library, login at top right.
          </p>
        </div>

        <RightColumn />
      </Layout>
    );
  }
}
